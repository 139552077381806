import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '../atoms/Typography';
import Getmuseuminfo from '../data/getmuseuminfo'
import AppleIcon from '@material-ui/icons/Apple';
import PlayLogo from '../images/playstorelogo.svg'
import AmazonLogo from '../images/amazonlogo.svg'
import Icon from '@material-ui/core/Icon'
import { OutboundLink } from "gatsby-plugin-gtag"

const styles = theme => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    opacity: 0.8,
    display: 'flex',
    borderRadius: 10,
    minWidth: 300,
    flexDirection:'column',
    alignItems: 'center',
    padding: 10
  },
  button: {
    margin: 10
  },
  iconsize: {
    height: 25,
    width: 19
  },
  menuLink: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    textDecoration: 'none'
  },

});

function DownloadPanel(props) {
  const { classes, museumname } = props;
  let museuminfo = Getmuseuminfo(museumname);
  return (
    <div style={{display:'flex', justifyContent:'center'}}>

    <Paper elevation={5} className={classes.paper} >
      <Typography color='primary' variant='button'>Download for free</Typography>
      <div>
        <OutboundLink className={classes.menuLink} href={museuminfo.ioSurl}>
        <IconButton aria-label='Apple Store'>
          <AppleIcon alt='Apple Store icon' />
        </IconButton>
        </OutboundLink>
        <OutboundLink className={classes.menuLink}  href={museuminfo.androidurl}>
        <IconButton aria-label='Google Play Store'>
          <Icon alt='Google Play store Icon'>
            <img className={classes.iconsize} src={PlayLogo} alt='Google Play store Icon' title='Google Play' loading='lazy'/>
          </Icon>
        </IconButton>
        </OutboundLink>
        <OutboundLink className={classes.menuLink}  href={museuminfo.amazonurl}>
        <IconButton aria-label='Amazon App Store'>
          <Icon alt='Amazon appstore Icon'>
            <img className={classes.iconsize} src={AmazonLogo} alt='Amazon Appstore Icon' title='Amazon Appstore' loading='lazy' />
          </Icon>
        </IconButton>
        </OutboundLink>
        </div>


    </Paper>
    </div>
  );
  }

  DownloadPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  };

  export default withStyles(styles)(DownloadPanel);
