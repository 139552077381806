import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "gatsby"
import Img from "gatsby-image"
import { withStyles, lighten } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '../atoms/Typography';
import {isMobile } from "react-device-detect";
import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardActionArea from '@material-ui/core/CardActionArea';
import Fade from '@material-ui/core/Fade';
import PlaceholderImage from '../content/blogposts/images/blogicon.png'

const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: lighten(theme.palette.primary.light, 0.5),
    paddingTop: 15,
    paddingBottom: 15
  },
  container: {
    height: '100%',
    width: '100%',
    minHeight: 100,

  },
  avatar: {
    width: 30,
    height: 30,
    borderRadius: '50%'
  },
});

function RelatedBlogsPanel(props) {
  const { classes, relatedPosts } = props;
  return (
    <section className={classes.root}>
      <Container  className={classes.container}>
        <Typography variant='h4' component='h3'>
          Related Posts
        </Typography>
        {relatedPosts.slice(0,isMobile?5:12).map(({ node }, i) => {
          let featuredImgFluid = null
          if (node.frontmatter.featuredImage){
          featuredImgFluid = node.frontmatter.featuredImage.childImageSharp.fluid;}

          return (
          <div key={node.id}>

            <Link
              to={node.frontmatter.path}
              underline="none"
              color="inherit"
              className={classes.link}
            >
            <Fade in={true} style={{ transitionDelay: `${(i+1)*200}ms` }}>
              <Card elevation={0} style={{backgroundColor: 'transparent', padding: 0, margin: 5}} >
              <CardActionArea style={{padding:0}}>
                <CardHeader style={{padding:0}} avatar={featuredImgFluid?<Img fluid={featuredImgFluid} className={classes.avatar} alt='Featured Image' title= 'Featured Image'/>:<Avatar src={PlaceholderImage} alt='placeholder image' className={classes.avatar}/>}
                title={<Typography variant='caption'>{ node.frontmatter.title}</Typography>}
                 />
                </CardActionArea>
              </Card>
            </Fade>
              </Link>
          </div>
        )})}
      </Container>
    </section>
  );
}

RelatedBlogsPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RelatedBlogsPanel);
