import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import {isMobile, isAndroid} from "react-device-detect";
import Getmuseuminfo from '../data/getmuseuminfo'
import { OutboundLink } from "gatsby-plugin-gtag"

const styles = theme => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    margin: 10
  },
  menuLink: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    textDecoration: 'none'
  },

});

function DownloadButton(props) {
  const { classes, museumname } = props;
  let museuminfo = Getmuseuminfo(museumname);
  return (
    <Container className={classes.root} component="section">
      <OutboundLink className={classes.menuLink} underline="none" href={isMobile?(isAndroid?museuminfo.androidurl:museuminfo.museuminfo.amazonurl):museuminfo.amazonurl}>
      <Button className={classes.button} variant='contained' color='primary'
      >
      Download for free
      </Button>
    </OutboundLink>

    </Container>
  );
  }

  DownloadButton.propTypes = {
  classes: PropTypes.object.isRequired,
  };

  export default withStyles(styles)(DownloadButton);
