import React from "react"
import SEO from "../components/SEO"
import { graphql } from "gatsby"
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '../atoms/Typography';
import {isMobile } from "react-device-detect";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image';
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from '../components/Layout'
import DownloadButton from '../components/DownloadButton'
import DownloadPanel from '../components/DownloadPanel'
import Artwavebackground from '../images/artwavebackground.png'
import MuseumInfoPanel from '../components/MuseumInfoPanel'
import RelatedBlogsPanel from '../components/RelatedBlogsPanel'
import Getmuseuminfo from '../data/getmuseuminfo'
import {handleLocation, isValidLocation, getCurrentLocation} from '../utils/checkValidLocation';
import {LinearProgress} from '@material-ui/core'
import Shutdown from '../components/Shutdown'

export default (props) => {
  const [location, setLocation] = React.useState(null)
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    let currentOrigin = window?.location?.origin || 'vusiem'
    if (currentOrigin.toLowerCase().includes('vusiem')||currentOrigin.toLowerCase().includes('museum-buddy')) {
    let currentLocation = getCurrentLocation()
    if (currentLocation?.location) {
      setLocation(currentLocation.location);
      setLoading(false);
    } else {
    var requestOptions = {
      method: 'GET',
    };
    
    fetch("https://api.geoapify.com/v1/ipinfo?&apiKey=3db745ec903945059d33e698d06ffd17", requestOptions)
      .then(response => response.json())
      .then(result => {handleLocation({locationCode: result?.country?.iso_code}); 
      if (result?.country?.iso_code !== 'DE') {setLocation(result?.country?.iso_code)}; 
      setLoading(false)})
      .catch(error => {console.log('error', error); handleLocation({locationCode: 'US'}); setLocation('US'); setLoading(false)});

    }} else {setLocation('US'); setLoading(false)}
  }, []);

    const museuminfo = props.data.mdx.frontmatter;
    const museumurl = Getmuseuminfo(museuminfo.name)
    const relatedPosts=props.data.allMdx.edges
    const useStyles = makeStyles(theme => ({
    root: {
      overflow: 'hidden',
      background: 'radial-gradient(circle, rgba(251,233,231,1) 0%, rgba(221,213,213,1) 87%, rgba(224,242,241,1) 100%)',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      height: '100%',
      position: 'relative',
      paddingTop: 70,
      top:0,
      left: 0
    },
    headlinestrip: {
      padding:10,
      display: 'block',
      textAlign: 'center',
    },
    firstpaneldiv: {
      position: 'relative',
      minHeight: '50vh',
      width: '100%',
      height: '100%',
      zIndex: 1,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
    firsttextpanel: {
      display:'flex',
      justifyContent:'center',
      flexDirection:'column',
      textAlign:'center',
      backgroundColor: theme.palette.grey.dark,

      opacity: 1,
      padding: 20
    },
    headertext: {
      color: 'white',

    },
    secondpaneldiv: {
      display:'flex',
      alignItems:'center',
      position: 'relative',
      minHeight: isMobile?'40vh':'30vh',
      width: '100%',
      height: '100%',
      zIndex: 1,
      paddingTop: 50,
      paddingBottom: 50,
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0.1))`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
    firstpanelmockupdiv: {
      height: '60vh',
      marginLeft: isMobile? -10: 0,
      marginBottom: -10,
      display:'flex',
      alignItems: 'flex-end'
    },
    firstpanelmockup:{
      zIndex: 3,
      width: 250,
    },
    firstcontainer: {
      margin: 0,
      padding:0,
    },
    downloadbutton: {
      display:'flex',
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    container: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
      display: 'flex',
      position: 'relative',
      width: '100%',
      minWidth: '95vw'
    },
    item: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(0, 2),
      textAlign: 'center'
    },
    image: {

      width: '100%'
    },
    title: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    smalltitle: {
      color: theme.palette.primary.light,
      fontWeight: 'bold',
      textTransform: 'none'
    },
    curvyLines: {
      pointerEvents: 'none',
      position: 'absolute',
      top: -180,
      left: isMobile?-30:-130,
      opacity: 0.1,
      maxWidth: '100vw'
    },
    infosection: {
      backgroundColor: theme.palette.primary.xlight
    },
    linebreakstext: {
      whiteSpace: 'pre-line',
      textAlign: 'justify',
      textJustify: 'inter-word',
      '&:first-letter': {
        fontWeight: 'bold',
        fontSize: 24,
        color: theme.palette.primary.main
      }
    },
  }));

  const classes = useStyles();

  return (
    loading? <LinearProgress />:(
    location ?<Layout>
      <CssBaseline />
        <SEO
          title={museuminfo.metatitle}
          description={museuminfo.description || museuminfo.excerpt}
          keywords = {museuminfo.keywords}
          featuredImage={museuminfo.firstpanelappimage.childImageSharp.fluid.src}
          url={props.location}
          frontmatter = {museuminfo}
          type='museum'
          installUrl = {{ioSurl:museumurl.ioSurl, androidurl: museumurl.androidurl, amazonurl: museumurl.amazonurl}}
        />
      <div className={classes.root}>
        <section>
        <div className={classes.headlinestrip}>
          <Typography variant='h2' component='h1' color='primary'>
            {museuminfo.title} App
          </Typography>
        </div>
        </section>
        <BackgroundImage
                  Tag="section"
                  className={classes.firstpaneldiv}
                  fluid={museuminfo.firstpanelbackgroundimage.childImageSharp.fluid}
                >

      <Grid container direction={isMobile?'column-reverse': 'row'} className={classes.firstcontainer}>
        <Grid item xs={12} sm={6}>
          <div style={{display:'flex', alignItems: 'center', justifyContent: 'flex-end', height: '100%', flexDirection: 'column', marginBottom: isMobile?5:20}}>
            <div className={classes.firsttextpanel}>
            <Typography variant='h4' component='h2' className={classes.headertext}>Take the Museum with you</Typography>
            <Typography variant='h6' component='h3' color='primary' className={classes.smalltitle}>Your companion App guide with object narrations, maps, museum info and  more </Typography>

          </div>
            <DownloadPanel museumname={museuminfo.name}/>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} >
        <div style={{display:'flex', justifyContent: isMobile?'center':'flex-start'}}>
          <div className={classes.firstpanelmockupdiv} >
            <Img fluid={museuminfo.firstpanelappimage.childImageSharp.fluid} alt='Museum Backdrop' title={museuminfo.title} className={classes.firstpanelmockup} imgStyle={{objectFit: 'contain'}} />
          </div>
        </div>
        </Grid>
      </Grid>
      </BackgroundImage>
      </div>

      <section>

      <Container maxWidth='md' className={classes.container} style={{flexDirection: 'column'}}>
        <Typography variant="h4" component='h2' marked="center" align="center">
          About the Museum Buddy App
        </Typography><br/>
      <Typography variant='body1'>
        <div className={classes.linebreakstext}>
        <MDXRenderer>{props.data.mdx.body}</MDXRenderer>
        </div>
      </Typography>
      </Container>
        <Container className={classes.container}>
          {<img
            src={Artwavebackground}
            className={classes.curvyLines}
            alt="curvy lines"
            title='Wave Background'
            loading='lazy'
          />}

          <Grid container>
            <Grid item xs={12} md={3}>
              <div className={classes.item}>
                <div className={classes.image}>
                  <Img fluid={museuminfo.screenshotmaps.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}} alt='screenshot1' title='Maps feature'/>
                </div>
                <div>
                <Typography variant="h6" component='h3' className={classes.title}>
                  Interactive Maps
                </Typography>
                <Typography variant="body2">
                  {'Easy-to-use maps. '}
                  {'Helps you zip through the numerous rooms at the museum without getting lost.'}
                </Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div className={classes.item}>
                <div className={classes.image}>
                  <Img fluid={museuminfo.screenshotdesc.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}  alt='screenshot2' title='Information feature'/>
                </div>
                <Typography variant="h6" component='h3' className={classes.title}>
                  Informative
                </Typography>
                <Typography variant="body2">
                  {'Informative and interesting commentaries. '}
                  {'Not too long. Not too short. Just right!'}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div className={classes.item}>
                <div className={classes.image}>
                  <Img fluid={museuminfo.screenshotsearch.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}} alt='screenshot3' title='Search feature'/>
                </div>
                <Typography variant="h6" component='h3' className={classes.title}>
                  Easy Search
                </Typography>
                <Typography variant="body2">
                  {'Find your favourite artefacts easily. '}
                  {'Spend time browsing & not in walking!'}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div className={classes.item}>
                <div className={classes.image}>
                  <Img fluid={museuminfo.screenshottour.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}} alt='screenshot4' title='Tours feature'/>
                </div>
                <Typography variant="h6" component='h3' className={classes.title}>
                  Self-paced tours
                </Typography>
                <Typography variant="body2">
                  {'Make the best use of your limited time. '}
                  {'Be it 1 hour, two hours or the whole day.'}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className={classes.infosection}>
        <Grid container>
          <Grid item xs={12} md={8}>
            <MuseumInfoPanel museumname={museuminfo.name}/>
          </Grid>
          <Grid item xs={12} md={4}>
            {/*<RelatedBlogsPanel relatedPosts={relatedPosts}/>*/}
          </Grid>
        </Grid>
      </section>
      <BackgroundImage
                Tag="section"
                className={classes.secondpaneldiv}
                fluid={museuminfo.bottompanelbackgroundimage.childImageSharp.fluid}
                alt='Background image'
                title={museuminfo.title}
              >
      <div className={classes.downloadbutton}>
      <Typography variant="h4" marked="center" align="center" component="h2">
        {isMobile?'Get it Now':' Know more. Walk less. Download now.'}
      </Typography>
      <DownloadButton museumname={museuminfo.name}/>
        </div>
      </BackgroundImage>

  </Layout>: <Shutdown />)
  );
}

export const pageQuery = graphql`
  query($path: String, $museumname: String) {

    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {frontmatter: {relatedCategory: {in: [$museumname, "All"]}, status: {eq: "publish"}}}
    ) {
      edges {
        node {
          frontmatter {
            title
            path
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }

    mdx(frontmatter: { path: { eq: $path } }) {
      body
      excerpt(pruneLength: 160)
      frontmatter {
        path
        name
        title
        appname
        keywords
        description
        metatitle
        firstpanelbackgroundimage {childImageSharp {fluid(maxWidth: 1800, webpQuality: 100) { ...GatsbyImageSharpFluid_withWebp }}}
        firstpanelappimage {childImageSharp {fluid(maxWidth: 1500) { ...GatsbyImageSharpFluid }}}
        screenshotmaps {childImageSharp {fluid(maxWidth:1500) { ...GatsbyImageSharpFluid }}}
        screenshotdesc {childImageSharp {fluid(maxWidth: 1500) { ...GatsbyImageSharpFluid }}}
        screenshotsearch {childImageSharp {fluid(maxWidth: 1500) { ...GatsbyImageSharpFluid }}}
        screenshottour {childImageSharp {fluid(maxWidth: 1500) { ...GatsbyImageSharpFluid }}}
        bottompanelbackgroundimage {childImageSharp {fluid(maxWidth: 1800, webpQuality: 100) { ...GatsbyImageSharpFluid_withWebp }}}
      }

    }
  }
`
