import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '../atoms/Typography';
import { useStaticQuery, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.primary.xlight,
    paddingTop: 15

  },
  container: {
    height: '100%',
    width: '100%',
    minHeight: 100,

  },
  linebreakstext: {
    whiteSpace: 'pre-line',
    textAlign: 'justify',
    textJustify: 'inter-word',
    fontSize: 13,
    '&:first-letter': {
      fontWeight: 'bold',
      fontSize: 20,
      color: theme.palette.primary.main
    }
  },
});


function MuseumInfoPanel(props) {
  const { classes, museumname } = props;
  const museuminfopanel = useStaticQuery(graphql`
    query museuminfopanelQuery {
      allMdx(filter: {frontmatter: {type: {eq: "other"}}}) {
        edges {
          node {
            id
            body
            frontmatter {
              name
              type
            }
            fileAbsolutePath
          }
        }
      }
      }
  `);

  const relatedmuseuminfo = museuminfopanel.allMdx.edges.filter(x=>x.node.frontmatter.name===museumname)

  return (
    <section className={classes.root}>
      {relatedmuseuminfo.length?<Container  className={classes.container}>
      <Typography variant='h4' component='h3'>
          Planning a day at the museum?
        </Typography>
        <div className={classes.linebreakstext}>
          <MDXRenderer>{relatedmuseuminfo[0].node.body}</MDXRenderer>
        </div>
      </Container>: null}
    </section>
  );
}

MuseumInfoPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MuseumInfoPanel);
